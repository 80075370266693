// client/src/AlphaMessage.js
import React from 'react';
import './styles.css';

function AlphaMessage() {
  return (
    <div className="alpha-message-container">
      <div className="text-content">
        <h2>Welcome to Virgil</h2>
        <p>
          Just as Virgil guided Dante through the Divine Comedy, we're here to guide you in bringing your cinematic dreams to life. 
          Our AI-powered screenwriting platform combines centuries of storytelling wisdom with cutting-edge technology to help you 
          craft compelling narratives that resonate with audiences.
        </p>
        <p>
          The application is currently in alpha. If you have not been invited, you can request an invite by emailing{' '}
          <a href="mailto:haroldrosenthal@virgil.com.ai">haroldrosenthal@virgil.com.ai</a> or{' '}
          <a href="mailto:support@virgil.com.ai">support@virgil.com.ai</a>.
        </p>
        <p>
          Existing users can download the app via our Discord server. Please check your invitation for the download link.
        </p>
      </div>
      <div className="image-content"></div>
    </div>
  );
}

export default AlphaMessage;