import React from 'react';
import './styles.css';

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <div className="footer">
      <p>
        © {currentYear} Virgil AI. All rights reserved. This software, including its AI-powered screenwriting platform and all associated content, is protected by U.S. and International copyright laws.
      </p>
    </div>
  );
}

export default Footer;