import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import './styles.css';

function SubscriptionInfo({ data }) {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  const handleCancelSubscription = async () => {
    if (window.confirm('Are you sure you want to cancel your subscription?')) {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        });

        const response = await fetch(`${process.env.REACT_APP_API_URL}/user/subscription/cancel`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          alert('Your subscription cancellation has been scheduled.');
          navigate('/');
        } else {
          console.error('Failed to cancel subscription');
          alert('An error occurred while cancelling your subscription.');
        }
      } catch (error) {
        console.error('Error cancelling subscription:', error);
        alert('An error occurred while cancelling your subscription.');
      }
    }
  };

  return (
    <div className="subscription-container">
      <h3 className="subscription-title">Your Subscription</h3>
      {[
        ['Plan', data.planName],
        ['Description', data.planDescription], 
        ['Price', `${data.priceCurrency} ${data.priceAmount} / ${data.interval}`],
        ['Status', data.status],
        ['Start Date', new Date(data.startDate).toLocaleDateString()],
        ['End Date', new Date(data.endDate).toLocaleDateString()],
        ['Usage', `${data.usageCount} / ${data.requestLimit}`]
      ].map(([label, value]) => (
        <p key={label} className="subscription-detail">
          <span className="detail-label">{label}:</span> {value}
        </p>
      ))}
      <div className="subscription-actions">
        <button className="subscription-button change-plan" onClick={() => navigate('/checkout')}>
          Change Plan
        </button>
        <button className="subscription-button cancel-plan" onClick={handleCancelSubscription}>
          Cancel Subscription
        </button>
      </div>
    </div>
  );
}

export default SubscriptionInfo;