// src/App.js
import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import CheckoutForm from './CheckoutForm';
import SubscriptionInfo from './SubscriptionInfo';
import AlphaMessage from './AlphaMessage';
import Footer from './Footer'; 
import './styles.css';
import { Routes, Route, useNavigate } from 'react-router-dom';


function App() {
  const {
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    getAccessTokenSilently,
  } = useAuth0();
  const navigate = useNavigate();

  if (isLoading) return <div>Loading...</div>;

  const isEmailVerified = user?.email_verified;

  const handleDeleteAccount = async () => {
    if (window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: 'openid profile email delete:current_user',
        });
        
        console.log('Access token obtained:', accessToken.substring(0, 20) + '...');
        console.log('Full access token:', accessToken);

        const response = await fetch(`${process.env.REACT_APP_API_URL}/user/delete`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.ok) {
          alert('Your account has been deleted.');
          logout({ returnTo: window.location.origin });
        } else {
          const errorData = await response.json();
          console.error('Error deleting account:', errorData);
          alert('An error occurred while deleting your account.');
        }
      } catch (error) {
        console.error('Error deleting account:', error);
        alert('An error occurred while deleting your account.');
      }
    }
  };

  
  const handleLogout = () => {
    logout({ 
      logoutParams: {
        returnTo: window.location.origin,
      }
    });
  };


  return (
    <div className="container">
      {!isAuthenticated && window.location.pathname === '/' && <AlphaMessage />}
      {!isAuthenticated ? (
        <div className="button-container">
          <button 
            className="button"  // keeping existing class name
            onClick={() => loginWithRedirect({
              screen_hint: 'signup'
            })}
          >
            Create Account
          </button>
          <button 
            className="button"  // keeping existing class name
            onClick={() => loginWithRedirect()}
          >
            Log In
          </button>
        </div>
      ) : !isEmailVerified ? (
        <div>
          <h2>Please Verify Your Email</h2>
          <p>
            A verification email has been sent to {user.email}. Please verify your email to continue.
          </p>
          <button
            className="button"
            onClick={() => logout({ returnTo: window.location.origin })}
          >
            Log Out
          </button>
        </div>
      ) : (
        <>
          <header className="header">
            <p className="welcome-message">Welcome, {user.name}</p>
            <button
              className="logout-button"
              onClick={handleLogout}  // Updated to use handleLogout
            >
              Log Out
            </button>
            <button
              className="delete-account-button"
              onClick={handleDeleteAccount}
            >
              Delete Account
            </button>
          </header>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/checkout" element={<CheckoutForm />} />
            <Route path="/success" element={<Success />} />
            <Route path="/cancel" element={<Cancel />} />
            <Route 
              path="/vrgldl" 
              element={
                <iframe 
                  src="/alpha.html" 
                  style={{
                    width: '100%',
                    height: '100vh',
                    border: 'none'
                  }}
                />
              } 
            />
          </Routes>
        </>
      )}
        <Footer />
    </div>
    
  );
}

function Success() {
  return (
    <div className="success-container">
      <h2>Payment Successful!</h2>
      <p>Thank you for your purchase. Your subscription is now active.</p>
    </div>
  );
}

function Cancel() {
  return (
    <div className="cancel-container">
      <h2>Payment Canceled</h2>
      <p>Your payment was canceled. You can try again or contact support if you need assistance.</p>
    </div>
  );
}

function Home() {
  const navigate = useNavigate();
  const { user, getAccessTokenSilently } = useAuth0();
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        setIsLoading(true);
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        });
        
        console.log('Access Token:', accessToken.substring(0, 20) + '...');
  
        const apiUrl = process.env.REACT_APP_API_URL;
        console.log('API URL:', apiUrl);
  
        const response = await fetch(`${apiUrl}/user/subscription`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        });
  
        console.log('Response Status:', response.status);
        console.log('Response Headers:', response.headers);
        console.log('Authorization Header:', `Bearer ${accessToken.substring(0, 20)}...`);
  
        const responseText = await response.text();
        console.log('Response Text:', responseText);
  
        if (response.status === 404) {
          // User has no subscription
          setSubscriptionData(null);
        } else if (!response.ok) {
          console.error('Error response from server:', responseText);
          throw new Error(`HTTP error! status: ${response.status}`);
        } else {
          const data = JSON.parse(responseText);
          setSubscriptionData(data);
        }
      } catch (error) {
        console.error('Error fetching subscription data:', error);
        setSubscriptionData(null);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchSubscription();
  }, [getAccessTokenSilently]);

  return (
    <div>
      <h2>Subscriptions</h2>
      {isLoading ? (
        <div>
          <p>Loading your subscription information...</p>
        </div>
      ) : !subscriptionData ? (
        <div>
          <p>You don't have an active subscription yet.</p>
          <p>Subscribe now to start using Virgil's AI-powered screenwriting platform!</p>
          <button className="button" onClick={() => navigate('/checkout')}>
            Subscribe Now
          </button>
        </div>
      ) : (
        <SubscriptionInfo data={subscriptionData} />
      )}
    </div>
  );
}

export default App;
