import React, { useState, useEffect } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { useAuth0 } from '@auth0/auth0-react';

function CheckoutForm() {
  const stripe = useStripe();
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  const [promoCode, setPromoCode] = useState('');
  const [selectedPlanId, setSelectedPlanId] = useState('');
  const [processing, setProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (isAuthenticated && user) {
      setIsEmailVerified(user.email_verified);
    }
  }, [isAuthenticated, user]);

  if (!isEmailVerified) {
    return (
      <div>
        <h2>Please Verify Your Email</h2>
        <p>
          You need to verify your email address before proceeding to checkout. Please check your inbox
          for a verification email.
        </p>
      </div>
    );
  }

  const plans = [
    {
      id: 'price_1QRidc2Ltbx5RG21yLogyiCf,price_1QRidc2Ltbx5RG21cfo7EvjO',  // Basic Plan + overage
      name: 'Basic Plan',
      price: '$30/month',
      description: 'Perfect for aspiring screenwriters. Includes 300 requests, then $0.25 per request.'
    },
    {
      id: 'price_1QRidc2Ltbx5RG21sBRvUAGt,price_1QRidc2Ltbx5RG21cfo7EvjO',  // Premium Plan + overage
      name: 'Premium Plan',
      price: '$60/month',
      description: 'Ideal for professional screenwriters. Includes 600 requests, then $0.25 per request.'
    },
    {
      id: 'price_1QRidc2Ltbx5RG21OfD23IfG,price_1QRidd2Ltbx5RG21gNfmhxCY',  // Pay-as-you-go base + usage
      name: 'Pay-as-you-go',
      price: '$10/month',
      description: 'Flexible option with $0.25 per request.'
    }
];

  const handlePlanSelect = (planId) => {
    setSelectedPlanId(planId);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe) {
      return;
    }

    if (!selectedPlanId) {
      setErrorMessage('Please select a subscription plan.');
      return;
    }

    setProcessing(true);

    try {
      const url = `${process.env.REACT_APP_API_URL}/payment/create-checkout-session`;
      console.log('Attempting fetch to:', url);
      
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await getAccessTokenSilently()}`
        },
        body: JSON.stringify({
          userId: user.sub,
          email: user.email,
          planId: selectedPlanId,
          promoCode,
        }),
      });

      console.log('Response status:', response.status);

      const result = await response.json();

      if (response.ok) {
        const { error: stripeError } = await stripe.redirectToCheckout({
          sessionId: result.id,
        });

        if (stripeError) {
          setErrorMessage(stripeError.message);
        }
      } else {
        setErrorMessage(result.error);
      }
    } catch (err) {
      console.error('Checkout error:', err);
      setErrorMessage('An unexpected error occurred.');
    } finally {
      setProcessing(false);
    }
  };

  const handleApplyPromo = async (e) => {
    e.preventDefault();
    console.log('Validating promo code:', promoCode);
    
    try {
        const url = `${process.env.REACT_APP_API_URL}/payment/create-checkout-session`;
        console.log('Attempting validation at:', url);

        const validateResponse = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${await getAccessTokenSilently()}`
            },
            body: JSON.stringify({
                userId: user.sub,
                email: user.email,
                planId: selectedPlanId,
                promoCode: promoCode.trim()
            })
        });

        console.log('Response status:', validateResponse.status);
        const validateResult = await validateResponse.json();
        console.log('Validation result:', validateResult);

        if (!validateResponse.ok) {
            throw new Error(validateResult.error || 'Invalid promotion code');
        }

        setErrorMessage('');
        alert('Valid promo code! Click Register & Pay to complete your purchase.');
    } catch (error) {
        console.error('Promo validation error:', {
            message: error.message,
            stack: error.stack
        });
        setErrorMessage(error.message);
    }
  };



  return (
    <div className="checkout-container">
      <form onSubmit={handleSubmit} className="checkout-form">
        <h2 className="checkout-title">Complete Your Subscription</h2>

        <div className="checkout-plan-options">
          {plans.map((plan) => (
            <div
              key={plan.id}
              className={`checkout-plan-box ${selectedPlanId === plan.id ? 'selected' : ''}`}
              onClick={() => handlePlanSelect(plan.id)}
            >
              <div className="checkout-plan-name">{plan.name}</div>
              <div className="checkout-plan-price">{plan.price}</div>
              <div className="checkout-plan-description">{plan.description}</div>
            </div>
          ))}
        </div>

        <div className="checkout-promo-container">
          <input
            type="text"
            placeholder="Promo Code"
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
            className="checkout-promo-input"
          />
          <button className="checkout-promo-button" onClick={handleApplyPromo}>
            Apply
          </button>
        </div>

        {errorMessage && (
          <div className="checkout-error-message">{errorMessage}</div>
        )}

        <button
          type="submit"
          disabled={!stripe || processing || !selectedPlanId}
          className="checkout-submit-button"
        >
          {processing ? 'Processing...' : 'Register & Pay'}
        </button>
      </form>
    </div>
  );
}

export default CheckoutForm;